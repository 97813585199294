<template>
  <div class="card-body">
    <div v-if="isLoading">
      <div class="text-center">{{ $t("commons.data_loading") }}...</div>
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-head-bg" style="width: 100%">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t("currencies_management.code") }}</th>
            <th scope="col">{{ $t("currencies_management.currency") }}</th>
            <th scope="col">{{ $t("currencies_management.purchase_try") }}</th>
            <th scope="col">
              {{ $t("currencies_management.new_purchase_try") }}
            </th>
            <th scope="col">{{ $t("currencies_management.sell_try") }}</th>
            <th scope="col">{{ $t("currencies_management.new_sell_try") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="filteredCurrencies.length === 0">
            <td colspan="7" class="text-center">{{ $t('commons.no_data_available') }}</td>
          </tr>
          <tr v-for="(item, key) in filteredCurrencies" :key="key">
            <td>{{ item.id }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.name }} ({{ item.code }})</td>
            <td>{{ item.buying_formatted }}</td>
            <td></td>
            <td>{{ item.selling_formatted }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.currenciesManagement.isLoading,
      currencies: (state) => state.currenciesManagement.currencies,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
    filteredCurrencies() {
      if(this.currencies.length > 0) return this.currencies.filter(val => !val.crypto)
      return []
    }
  },
  methods: {
    ...mapActions("currenciesManagement", ["GET_FINANCE_CRM_CURRENCIES"]),
  },
  created() {
    this.GET_FINANCE_CRM_CURRENCIES()
  }
};
</script>
