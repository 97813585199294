<template>
  <div class="card-body">
    <div class="alert alert-warning text-dark" role="alert">
      {{ $t("rates_management.supplier_prices_desc") }}
    </div>
    <div v-if="isLoading">
      <div class="text-center">{{ $t("commons.data_loading") }}...</div>
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-head-bg" style="width: 100%">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t("currencies_management.code") }}</th>
            <th scope="col">{{ $t("currencies_management.currency") }}</th>
            <th scope="col">{{ $t("currencies_management.purchase_try") }}</th>
            <th scope="col">
              {{ $t("currencies_management.new_purchase_try") }}
            </th>
            <th scope="col">{{ $t("currencies_management.sell_try") }}</th>
            <th scope="col">{{ $t("currencies_management.new_sell_try") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="currencies.length === 0">
            <td colspan="7" class="text-center">{{ $t('commons.no_data_available') }}</td>
          </tr>
          <tr v-for="(item, idx) in currencies" :key="idx">
            <td class="align-middle">{{ item.id }}</td>
            <td class="align-middle">{{ item.code }}</td>
            <td class="align-middle">{{ item.name }} ({{ item.code }})</td>
            <td class="align-middle">{{ item.buying_formatted }}</td>
            <td class="align-middle">
              <currency-input
                class="form-control"
                v-if="Object.keys(formData).length > 0"
                autocomplete="off"
                :precision="2"
                :distractionFree="false"
                v-model="formData[item.id].buy"
                :placeholder="$t('rates_management.enter_in_dollars')"
              />
            </td>
            <td class="align-middle">{{ item.selling_formatted }}</td>
            <td class="align-middle">
              <currency-input
                class="form-control"
                v-if="Object.keys(formData).length > 0"
                autocomplete="off"
                :precision="2"
                :distractionFree="false"
                v-model="formData[item.id].sell"
                :placeholder="$t('rates_management.enter_in_dollars')"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--begin::Actions-->
    <div class="d-flex justify-content-end border-top mt-5 pt-10">
      <button
        type="button"
        @click="handleSubmit"
        class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
      >
        {{ $t("commons.submit") }}
      </button>
    </div>
    <!--end::Actions-->
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.ratesManagement.isLoading,
      currencies: (state) => state.ratesManagement.supplierPrices,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
  },
  methods: {
    ...mapActions('ratesManagement', ['GET_RATES', 'UPDATE_RATE']),
    handleSubmit() {
      const entries = Object.entries(this.formData);
      // eslint-disable-next-line no-unused-vars
      let filtered = entries.filter(([key, value]) => (!!value.buy || !!value.sell));
      if(entries.length === 0 || filtered.length === 0) {
        return;
      }
      
      const _formData = Object.fromEntries(filtered)
      // console.log(JSON.stringify({ rate: _formData }));
      const vm = this;
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("commons.approve"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then(function (result) {
        if (result.value) {
          vm.UPDATE_RATE({ rate: _formData });
        }
      });
    },
  },
  created() {
    this.GET_RATES().then(() => {
      const newFormData = {};
      if(this.currencies.length > 0) {
        this.currencies?.forEach(val => {
          newFormData[val.id] = { buy: null, sell: null }
        });
      }
      this.formData = newFormData;
    })
  }
};
</script>
